import axios from "axios";
import ClearSessionStorage from "../ClearSessionStorage";

// Axios 인스턴스 생성
const instance = axios.create();

// Axios 요청 전에 수행할 로직
instance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    const sessionAccessToken: any = sessionStorage.getItem("accessToken");

    if (sessionAccessToken) {
      newConfig.headers.Authorization = `Bearer ${sessionAccessToken}`;
    }
    return newConfig;
  },
  (error) => {
    // 요청 오류 처리
    console.log(error);
    return Promise.reject(error);
  }
);

// Axios 응답 후에 수행할 로직
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // 1) 만약, 만료된 accessToken인 경우
    if (error.response && error.response.data.code === 3003) {
      console.log("어세스토큰 만료 : 3003");
      try {
        const response = await axios.get(
          process.env.REACT_APP_SERVER_API + "/auth/refresh",
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
            withCredentials: true,
          }
        );
        if (response.data.code === 1000) {
          const accessToken = response.data.data.accessToken;
          const sessionAccessToken: any = sessionStorage.getItem("accessToken");
          sessionStorage.setItem("accessToken", accessToken);
          console.log("accessToken이 업데이트되었습니다.");
          error.config.headers.Authorization = `Bearer ${sessionAccessToken}`;
          return axios.request(error.config); // 이전 요청 재시도
        } else {
          console.log("새로운 accessToken을 받지 못했습니다.");
        }
      } catch (refreshError) {
        console.log("refreshError:", refreshError);
        alert("로그인 시간이 만료됐습니다. 재로그인해주세요. ");
        ClearSessionStorage();
        window.location.href = "/";
      }
    } else if (error.response && error.response.data.code === 3006) {
      console.log("만료된 리프레쉬 토큰입니다. 다시 로그인해주세요. ");
      ClearSessionStorage();
      window.location.href = "/";
      return;
    }
    return Promise.reject(error);
  }
);

export default instance;
