import React from "react";
import NoticeContainer from "../components/UI/NoticeContainer";

const NoticePage = () => {
  return (
    <div>
      <NoticeContainer />
    </div>
  );
};

export default NoticePage;
