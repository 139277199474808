import { useState } from "react";
import instance from "../Feat/axios/instance";

const AddNoticeContainer = () => {
  const [addNewData, setAddNewData]: any = useState({
    title: "",
    content: "",
  });

  const requestAddNewData = async () => {
    try {
      const requestData = {
        title: addNewData.title,
        content: addNewData.content,
      };
      const url = process.env.REACT_APP_SERVER_API + "/admin/notices";
      const res = await instance.post(url, requestData);
      if (res.data.status == 200) {
        alert("등록이 정상적으로 되었습니다.");
        window.location.href = "/notice";
      } else {
        alert(`${res.data.message}`);
        return;
      }
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  return (
    <div className="flex items-center justify-center mt-[40px] ">
      <div className="border border-black w-[70%] p-5 space-y-5">
        <div className="flex">
          <h3 className="w-[120px]">name</h3>
          <input
            type="text"
            className="border border-gray-400 rounded-md h-[36px]"
            onChange={(e) => {
              setAddNewData({ ...addNewData, title: e.target.value });
            }}
          />
        </div>
        <div className="flex">
          <h3 className="w-[120px]">description</h3>
          <textarea
            className="border border-gray-400 rounded-md h-[120px] w-[360px]"
            onChange={(e) => {
              setAddNewData({ ...addNewData, content: e.target.value });
            }}
          />
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="w-[120px] border border-gray-400  p-2 rounded-md hover:bg-gray-500 hover:text-white cursor-pointer"
            onClick={() => {
              requestAddNewData();
            }}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddNoticeContainer;
