import { useParams } from "react-router-dom";
import instance from "../Feat/axios/instance";
import { useEffect, useState } from "react";
import axios from "axios";

const EventDetailContainer = () => {
  const { id }: any = useParams();
  const [data, setData]: any = useState([]);

  const [file, setFile] = useState<File | null>(null);
  const [editMainImage, setEditMainImage] = useState("");
  const [editBannerImage, setEditBannerImage] = useState("");

  let selectedFile: any;
  let imgUrl: any;

  const getDetailInfo = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_API + "/admin/events/info/" + id;
      const res = await instance.get(url);
      if (res.status == 200) {
        setData(res.data.data);
        console.log("처음 받아온 정보", res.data.data);
      }
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile); // 'image'는 서버에서 파일을 받는 필드 이름과 동일해야 합니다.
        // 파일 업로드 요청을 보냅니다.
        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // 파일 업로드 성공 시 응답 데이터를 사용할 수 있습니다.
        if (response.data.data.url) {
          imgUrl = response.data.data.url;
          alert("이미지 업로드가 되었습니다.");
          return { status: true, imageURL: imgUrl };
        }
      }
    } catch (error) {
      console.error("파일 업로드 오류:", error);
      alert("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setAction: any
  ) => {
    selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile !== null) {
      const requestImgUrlResult: any = await requestImgUrl();
      setAction(requestImgUrlResult.imageURL);
    }
  };

  const requestEditData = async (eventId: number) => {
    const postData = {
      name: data.name,
      description: data.description,
      mainImage: editMainImage !== "" ? editMainImage : data.mainImage,
      bannerImage: editBannerImage !== "" ? editBannerImage : data.bannerImage,
      releasedDate: data.releasedAt,
      expiredDate: data.expiredAt,
      isMain: data.isMain,
      status: data.status,
    };
    console.log("수정", postData);
    try {
      const url =
        process.env.REACT_APP_SERVER_API + "/admin/events/editing/" + eventId;
      const res = await instance.post(url, postData);
      console.log("res:", res);
      if (res.data.status == 200) {
        alert("등록이 정상적으로 되었습니다.");
        window.location.href = "/main";
      } else {
        console.log("res", res);
        alert(`${res.data.message}`);
        return;
      }
    } catch (error: any) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getDetailInfo();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-[40px]">
      <h3 className="text-2xl">이벤트 상세페이지</h3>
      <div className="border border-black w-[70%] space-y-5 p-5">
        <div className="flex">
          <h3 className="w-[160px]">NFT ID</h3>
          <h3>{data.eventId}</h3>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">name</h3>
          <input
            type="text"
            defaultValue={data.name}
            onChange={(e: any) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </div>
        <div className="flex">
          <h3 className="w-[160px]">description</h3>
          <input
            type="text"
            defaultValue={data.description}
            onChange={(e: any) => {
              setData({ ...data, description: e.target.value });
            }}
          />
        </div>
        <div className="flex">
          <h3 className="w-[160px]">mainImage</h3>
          <div className="flex flex-col">
            <h3>기존 이미지</h3>
            <img src={data.mainImage} className="w-[100px] h-[100px]" />
          </div>
          <div className="flex flex-col ml-[80px]">
            <h3>변경 이미지</h3>
            <img src={editMainImage} className="w-[100px] h-[100px]" />
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e, setEditMainImage);
              }}
            />
          </div>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">bannerImage</h3>
          <div className="flex flex-col">
            <h3>기존 이미지</h3>
            <img src={data.bannerImage} className="w-[100px] h-[100px]" />
          </div>
          <div className="flex flex-col ml-[80px]">
            <h3>변경 이미지</h3>
            <img src={editBannerImage} className="w-[100px] h-[100px]" />
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e, setEditBannerImage);
              }}
            />
          </div>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">releasedAt</h3>
          <h3>{data.releasedAt}</h3>
          <div className="flex flex-col ml-[40px]">
            <h3>변경 날짜</h3>
            <div className="flex items-center justify-center space-x-3">
              <input
                type="date"
                id="date"
                defaultValue={data.releasedAt}
                onChange={(e: any) => {
                  setData({ ...data, releasedAt: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">expiredAt</h3>
          <h3>{data.expiredAt}</h3>
          <div className="flex flex-col ml-[40px]">
            <h3>변경 날짜</h3>
            <div className="flex items-center justify-center space-x-3">
              <input
                type="date"
                id="date"
                defaultValue={data.expiredAt}
                onChange={(e: any) => {
                  setData({ ...data, expiredAt: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">메인 활성화 여부</h3>
          <select
            onChange={() => {
              setData({ ...data, isMain: !data.isMain });
            }}
            defaultValue={data.isMain ? "true" : "false"}
          >
            <option value="false">비활성화</option>
            <option value="true">활성화</option>
          </select>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">이벤트 활성화 여부</h3>

          <select
            value={data.status == "ACTIVE" ? "ACTIVE" : "INACTIVE"}
            onChange={(e: any) => {
              setData({ ...data, status: e.target.value });
            }}
          >
            <option value="INACTIVE">비활성화</option>
            <option value="ACTIVE">활성화</option>
          </select>
        </div>
        <div className="w-full  flex items-center justify-center">
          <button
            type="button"
            className="bg-gray-600 text-white w-[120px] h-[40px] cursor-pointer hover:bg-gray-400"
            onClick={() => {
              requestEditData(id);
            }}
          >
            수정하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventDetailContainer;
