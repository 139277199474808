import AddEventContainer from "../components/UI/AddEventContainer";

const AddEventPage = () => {
  return (
    <div>
      <AddEventContainer />
    </div>
  );
};

export default AddEventPage;
