import Pagination from "react-js-pagination";
import instance from "../Feat/axios/instance";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DomainListContainer = () => {
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 10;
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage - 1);
  };
  const [totalItem, setTotalItem] = useState(0);

  const getEventList = async () => {
    const url =
      process.env.REACT_APP_SERVER_API +
      "/admin/agencyInfo?page=" +
      page +
      "&size=10";
    const res = await instance.get(url);
    if (res.status == 200) {
      setTotalItem(res.data.data.totalElements);
      setDataList(res.data.data.content);
    }
    try {
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  const addBusinessLicense = async (inputString: string) => {
    const url = process.env.REACT_APP_SERVER_API + "/admin/agencyInfo/register";
    const res = await instance.post(url, { businessLicense: inputString });
    console.log("res:", res);
    if (res.data.status == 200) {
      alert("등록이 정상적으로 되었습니다.");
      window.location.href = "/domain";
    } else {
      alert(`${res.data.message}`);
      return;
    }
  };

  const deleteBusinessLicense = async (businessLicenseNumber: number) => {
    const url =
      process.env.REACT_APP_SERVER_API +
      "/admin/agencyInfo/" +
      businessLicenseNumber;
    const res = await instance.delete(url);
    if (res.data.status == 200) {
      alert("삭제가 정상적으로 되었습니다.");
      window.location.href = "/domain";
    } else {
      alert(`${res.data.message}`);
      return;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      getEventList();
    } else {
      alert("로그인 먼저 진행해주세요.");
      window.location.href = "/";
    }
  }, [page]);

  return (
    <div className="mt-[40px]">
      <div className="lg:w-2/3 w-full mx-auto overflow-auto">
        <div className="w-full bg-blue-200">
          <div
            className="float-right bg-gray-200 p-2 mb-3 cursor-pointer hover:bg-gray-400"
            onClick={() => {
              var inputString = prompt("사업자 등록번호를 입력하세요.");
              if (inputString === null) {
                return;
              } else {
                addBusinessLicense(inputString);
              }
            }}
          >
            등록하기
          </div>
        </div>
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                agencyInfoId
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                agencyName
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                businessLicense
              </th>
              <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                삭제
              </th>
            </tr>
          </thead>
          <tbody>
            {dataList.map((item: any, index) => {
              return (
                <tr key={index}>
                  <td className="px-4 py-3">{item.agencyInfoId}</td>
                  <td className="px-4 py-3">{item.agencyName}</td>
                  <td className="px-4 py-3">{item.businessLicense}</td>
                  <td
                    className="px-4 py-3 hover:text-red-400 cursor-pointer"
                    onClick={() => {
                      deleteBusinessLicense(item.agencyInfoId);
                    }}
                  >
                    삭제
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="w-full flex items-center justify-center mt-[40px]">
          <Pagination
            activePage={page + 1}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItem} // totalPages를 사용합니다.
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
            activeClass=" text-brand100 text-[22px] font-semibold "
            disabledClass="text-[#9f9f9f]"
            hideFirstLastPages={true}
            innerClass="text-[#9f9f9f]"
            prevPageText={<div className="text-brand100">&#60;</div>}
            nextPageText={<div className="text-brand100">&#62;</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default DomainListContainer;
