import EventDetailContainer from "../components/UI/EventDetailContainer";

const EventDetailPage = () => {
  return (
    <div>
      <EventDetailContainer />
    </div>
  );
};

export default EventDetailPage;
