import { useEffect, useState } from "react";
import instance from "../Feat/axios/instance";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";

const MainListContainer = () => {
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 10;
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage - 1);
  };
  const [totalItem, setTotalItem] = useState(0);

  const getEventList = async () => {
    const url =
      process.env.REACT_APP_SERVER_API +
      "/admin/events?page=" +
      page +
      "&size=10";
    const res = await instance.get(url);
    if (res.status == 200) {
      setTotalItem(res.data.data.totalElements);
      setDataList(res.data.data.content);
    }
    try {
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  const deleteEvent = async (businessLicenseNumber: number) => {
    const url =
      process.env.REACT_APP_SERVER_API +
      "/admin/agencyInfo/" +
      businessLicenseNumber;
    const res = await instance.delete(url);
    if (res.data.status == 200) {
      alert("삭제가 정상적으로 되었습니다.");
      window.location.href = "/domain";
    } else {
      alert(`${res.data.message}`);
      return;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      getEventList();
    } else {
      alert("로그인 먼저 진행해주세요.");
      window.location.href = "/";
    }
  }, [page]);

  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              이벤트 리스트
            </h1>
            <p className=" text-base mt-[40px]">
              관리자 페이지입니다.
              <br />
              이벤트 리스트가 보여집니다.
            </p>
          </div>
          <div className="lg:w-2/3 w-full mx-auto overflow-auto">
            <table className="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                    evnetId
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                    mainImage
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                    bannerImage
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                    status
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                    isMain
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                    삭제
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((item: any, index) => {
                  return (
                    <tr
                      key={index}
                      className="hover:bg-gray-300 cursor-pointer"
                      onClick={() => {
                        navigate(`/event/detail/${item.eventId}`);
                      }}
                    >
                      <td className="px-4 py-3">{item.eventId}</td>
                      <td className="px-4 py-3">
                        <img
                          src={item.mainImage}
                          className="w-[100px] h-[100px]"
                        />
                      </td>
                      <td className="px-4 py-3">
                        <img
                          src={item.bannerImage}
                          className="w-[100px] h-[100px]"
                        />
                      </td>
                      <td className="px-4 py-3 text-lg text-gray-900">
                        {item.status}
                      </td>
                      {item.isMain == true ? <td>활성</td> : <td>비활성</td>}
                      <td className="px-4 py-3 text-lg text-gray-900">삭제</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="w-full flex items-center justify-center mt-[40px]">
              <Pagination
                activePage={page + 1}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={totalItem} // totalPages를 사용합니다.
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
                activeClass=" text-brand100 text-[22px] font-semibold "
                disabledClass="text-[#9f9f9f]"
                hideFirstLastPages={true}
                innerClass="text-[#9f9f9f]"
                prevPageText={<div className="text-brand100">&#60;</div>}
                nextPageText={<div className="text-brand100">&#62;</div>}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainListContainer;
