import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../Feat/axios/instance";

const NoticeDetailContainer = () => {
  const { id }: any = useParams();
  const [data, setData]: any = useState([]);

  const getDetailInfo = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_API + "/admin/notices/info/" + id;
      const res = await instance.get(url);
      if (res.status == 200) {
        setData(res.data.data);
      }
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  const requestEditData = async (eventId: number) => {
    const postData = {
      title: data.title,
      content: data.content,
    };
    try {
      const url =
        process.env.REACT_APP_SERVER_API + "/admin/notices/" + eventId;
      const res = await instance.put(url, postData);
      if (res.data.status == 200) {
        alert("수정이 정상적으로 되었습니다.");
        window.location.href = "/notice";
      } else {
        alert(`${res.data.message}`);
        return;
      }
    } catch (error: any) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    getDetailInfo();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center mt-[40px]">
      <h3 className="text-2xl">공지사항 상세페이지</h3>
      <div className="border border-black w-[70%] space-y-5 p-5">
        <div className="flex">
          <h3 className="w-[160px]">공지사항 ID</h3>
          <h3>{data.noticeId}</h3>
        </div>
        <div className="flex">
          <h3 className="w-[160px]">공지명</h3>
          <input
            type="text"
            defaultValue={data.title}
            onChange={(e: any) => {
              setData({ ...data, title: e.target.value });
            }}
          />
        </div>
        <div className="flex">
          <h3 className="w-[160px]">본문</h3>
          <textarea
            defaultValue={data.content}
            className="w-[460px] h-[460px]"
            onChange={(e: any) => {
              setData({ ...data, content: e.target.value });
            }}
          />
        </div>

        <div className="w-full  flex items-center justify-center">
          <button
            type="button"
            className="bg-gray-600 text-white w-[120px] h-[40px] cursor-pointer hover:bg-gray-400"
            onClick={() => {
              requestEditData(id);
            }}
          >
            수정하기
          </button>
        </div>
      </div>
    </div>
  );
};
export default NoticeDetailContainer;
