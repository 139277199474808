import React from "react";
import AddNoticeContainer from "../components/UI/AddNoticeContainer";

const AddNoticePage = () => {
  return (
    <div>
      <AddNoticeContainer />
    </div>
  );
};

export default AddNoticePage;
