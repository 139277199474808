import React from "react";
import DomainListContainer from "../components/UI/DomainListContainer";

const DomainListPage = () => {
  return (
    <div>
      <DomainListContainer />
    </div>
  );
};

export default DomainListPage;
