import axios from "axios";
import { useEffect, useState } from "react";
import instance from "../Feat/axios/instance";

const AddEventContainer = () => {
  const [mainImageURL, setMainImageURL] = useState("");
  const [bannerImageuRL, setBannerImageURL] = useState("");

  let selectedFile: any;
  let imgUrl: any;

  const [addNewData, setAddNewData]: any = useState({
    name: "",
    description: "",
    mainImage: "",
    bannerImage: "",
    releasedDate: "",
    expiredDate: "",
    isMain: true,
  });

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile);

        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // 파일 업로드 성공 시 응답 데이터를 사용할 수 있습니다.
        if (response.data.data.url) {
          imgUrl = response.data.data.url;
          alert("이미지 업로드가 되었습니다.");
          return { status: true, imageURL: imgUrl };
        }
      }
    } catch (error) {
      console.error("파일 업로드 오류:", error);
      alert("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setAction: any
  ) => {
    selectedFile = e.target.files?.[0] || null;
    if (selectedFile !== null) {
      const requestImgUrlResult: any = await requestImgUrl();
      setAction(requestImgUrlResult.imageURL);
    }
  };

  const requestAddNewData = async () => {
    try {
      const requestData = {
        name: addNewData.name,
        description: addNewData.description,
        mainImage: mainImageURL,
        bannerImage: bannerImageuRL,
        releasedDate: addNewData.releasedDate,
        expiredDate: addNewData.expiredDate,
        isMain: addNewData.isMain,
      };
      const url =
        process.env.REACT_APP_SERVER_API + "/admin/events/registration";
      const res = await instance.post(url, requestData);
      if (res.data.status == 200) {
        alert("등록이 정상적으로 되었습니다.");
        window.location.href = "/main";
      } else {
        alert(`${res.data.message}`);
        return;
      }
    } catch (error: any) {
      alert(`${error.response.data.message}`);
      return;
    }
  };

  useEffect(() => {
    console.log("addNewData:", addNewData);
  }, [addNewData]);
  return (
    <div className="flex items-center justify-center mt-[40px] ">
      <div className="border border-black w-[70%] p-5 space-y-5">
        <div className="flex">
          <h3 className="w-[120px]">name</h3>
          <input
            type="text"
            className="border border-gray-400 rounded-md h-[36px]"
            onChange={(e) => {
              setAddNewData({ ...addNewData, name: e.target.value });
            }}
          />
        </div>
        <div className="flex">
          <h3 className="w-[120px]">description</h3>
          <textarea
            className="border border-gray-400 rounded-md h-[120px] w-[360px]"
            onChange={(e) => {
              setAddNewData({ ...addNewData, description: e.target.value });
            }}
          />
        </div>

        <div className="flex">
          <h3 className="w-[120px]">mainImage</h3>
          <div className="flex flex-col">
            <img src={mainImageURL} className="w-[150px] h-[150px]" />
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e, setMainImageURL);
              }}
            />
          </div>
        </div>

        <div className="flex">
          <h3 className="w-[120px]">bannerImage</h3>
          <div className="flex flex-col">
            <img src={bannerImageuRL} className="w-[150px] h-[150px]" />
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e, setBannerImageURL);
              }}
            />
          </div>
        </div>

        <div className="flex">
          <h3 className="w-[120px]">releasedDate</h3>
          <div className="flex items-center justify-center ">
            <input
              type="date"
              id="date"
              onChange={(e: any) => {
                setAddNewData({ ...addNewData, releasedDate: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="flex">
          <h3 className="w-[120px]">expiredDate</h3>
          <div className="flex items-center justify-center ">
            <input
              type="date"
              id="date"
              onChange={(e: any) => {
                setAddNewData({ ...addNewData, expiredDate: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="flex">
          <h3 className="w-[120px]">메인 활성화 여부</h3>
          <select
            onChange={() => {
              setAddNewData({ ...addNewData, isMain: !addNewData.isMain });
            }}
            defaultValue={addNewData.isMain}
          >
            <option value="false">비활성화</option>
            <option value="true">활성화</option>
          </select>
        </div>
        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="w-[120px] border border-gray-400  p-2 rounded-md hover:bg-gray-500 hover:text-white cursor-pointer"
            onClick={() => {
              requestAddNewData();
            }}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEventContainer;
