import NoticeDetailContainer from "../components/UI/NoticeDetailContainer";

const NoticeDetailPage = () => {
  return (
    <div>
      <NoticeDetailContainer />
    </div>
  );
};

export default NoticeDetailPage;
