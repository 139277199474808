import MainListContainer from "../components/UI/MainListContainer";

const MainPage = () => {
  return (
    <div>
      <MainListContainer />
    </div>
  );
};

export default MainPage;
