import { useLocation, useNavigate, useParams } from "react-router-dom";
import whatconLogo from "../common/images/6_steps.jpeg";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  if (currentPath == "/") {
    return null;
  }
  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
          <a
            className="mr-5 hover:text-gray-900 cursor-pointer text-gray-500"
            onClick={() => {
              navigate("/main");
            }}
          >
            이벤트
          </a>
          <a
            className="mr-5 hover:text-gray-900 cursor-pointer text-gray-500"
            onClick={() => {
              navigate("/domain");
            }}
          >
            사업자 등록번호
          </a>
          <a
            className="mr-5 hover:text-gray-900 cursor-pointer text-gray-500"
            onClick={() => {
              navigate("/notice");
            }}
          >
            공지사항
          </a>
        </nav>
        <a
          onClick={() => {
            navigate("/main");
          }}
          className="cursor-pointer  flex flex-col order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0"
        >
          <img src={whatconLogo} className="w-[50px] h-[50px]" />
        </a>
        <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
          <button
            onClick={() => {
              sessionStorage.clear();
              navigate("/");
            }}
            className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
          >
            로그아웃
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
