import AddEventPage from "./pages/AddEventPage";
import AddNoticePage from "./pages/AddNoticePage";
import DomainListPage from "./pages/DomainListPage";
import EventDetailPage from "./pages/EventDetailPage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import NoticeDetailPage from "./pages/NoticeDetailPage";
import NoticePage from "./pages/NoticePage";
import "./styles/App.css";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/main" element={<MainPage />} />
      <Route path="/event/new" element={<AddEventPage />} />
      <Route path="/event/detail/:id" element={<EventDetailPage />} />
      <Route path="/domain" element={<DomainListPage />} />
      <Route path="/notice" element={<NoticePage />} />
      <Route path="/notice/new" element={<AddNoticePage />} />
      <Route path="/notice/detail/:id" element={<NoticeDetailPage />} />
    </Routes>
  );
}

export default App;
